import React from 'react'
import Signup from "../features/auth/components/Signup"

const SignupPage = () => {
  return (
    <div>
      <Signup />
    </div>
  )
}

export default SignupPage