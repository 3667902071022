import React from 'react'
import ForgotPassword from "../features/auth/components/ForgotPassword"

const ForgotPasswordPage = () => {
  return (
    <div>
      <ForgotPassword />
    </div>
  )
}

export default ForgotPasswordPage